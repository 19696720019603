import { PageInfo } from 'components/shared/page-info';
import React from 'react';

import styles from './about-hero.module.scss';

export const Hero = () => (
  <section className={`${styles.wrapper}`}>
    <PageInfo
      className={styles.pageInfo}
      title={'This is k6'}
      description={'How we got started on the journey to providing the best load testing platform on the planet'}
      withBgPattern
    />
  </section>
);
