import classNames from 'classnames';
import { Heading } from 'components/shared/heading';
import { ItemCard } from 'components/shared/item-card';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import BlogIcon from './svg/blog.inline.svg';
import GithubIcon from './svg/github.inline.svg';
import LinkedInIcon from './svg/linkedin.inline.svg';
import TwitterIcon from './svg/twitter.inline.svg';
import styles from './team.module.scss';

export const Team = () => {
  const {
    allTeamMembersJson: { edges: MEMBERS },
  } = useStaticQuery(graphql`
    query teamMembers {
      allTeamMembersJson {
        edges {
          node {
            name
            position
            gitLink
            twLink
            blogLink
            linkedInLink
            location
            image {
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
          }
        }
      }
    }
  `);

  return (
    <div className={styles.exteriorWrapper}>
      <section className={`container ${styles.wrapper}`}>
        <div className={'row'}>
          {MEMBERS.map(({ node: { name, position, gitLink, twLink, blogLink, linkedInLink, image, location } }, i) => (
            <div className={`col-xl-3 col-lg-4 col-sm-6 col-12 ${styles.cardSelectorHook}`} key={`membercard-${i}`}>
              <div className={styles.outerItemWrapper}>
                <div className={styles.itemImageWrapper}>
                  <GatsbyImage image={getImage(image)} objectFit="cover" className={styles.itemImage} alt={name} />
                </div>
                <ItemCard as={'div'} label={styles.itemWrapper}>
                  <div className={styles.itemContent}>
                    <Heading className={styles.itemTitle} tag={'h3'} size={'md'}>
                      {name}
                    </Heading>
                    <p className={styles.itemDescription}>{position}</p>
                    <div className={styles.itemIcons}>
                      {gitLink && (
                        <a className={styles.iconLink} href={gitLink}>
                          <GithubIcon />
                        </a>
                      )}
                      {twLink && (
                        <a className={classNames(styles.iconLink, styles.last)} href={twLink}>
                          <TwitterIcon />
                        </a>
                      )}
                      {blogLink && (
                        <a className={classNames(styles.iconLink, styles.last)} href={blogLink}>
                          <BlogIcon />
                        </a>
                      )}
                      {linkedInLink && (
                        <a className={classNames(styles.iconLink, styles.last)} href={linkedInLink}>
                          <LinkedInIcon />
                        </a>
                      )}
                    </div>
                  </div>
                  <div className={styles.itemFooter}>
                    <p className={styles.itemLocation}>{location}</p>
                  </div>
                </ItemCard>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};
