import { SEO } from 'components/blocks/seo';
import { Hero } from 'components/pages/about/hero';
import { Story } from 'components/pages/about/story';
import { Team } from 'components/pages/about/team';
import { Feedbacks } from 'components/pages/home/feedbacks';
import { CTA } from 'components/shared/cta';
import { DefaultLayout } from 'layouts/default-layout';
import React from 'react';
import SEO_DATA from 'utils/seo-metadata';

const About = () => (
  <DefaultLayout>
    <Hero />
    <Story />
    <Team />
    <Feedbacks />
    <CTA
      title={'Want to work with us?'}
      description={"k6 is on a mission to democratize load testing, and we're always looking for great people."}
      buttonText={'Check our jobs'}
      buttonURL={'/jobs'}
      themeLight
    />
  </DefaultLayout>
);

export default About;

export const Head = () => <SEO {...SEO_DATA.about} />;
