import classNames from 'classnames';
import { CodeInline } from 'components/shared/code';
import { Heading } from 'components/shared/heading';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import styles from './story.module.scss';

export const Story = () => {
  const { storyGraphic } = useStaticQuery(graphql`
    query graphicsQuery {
      storyGraphic: file(name: { regex: "/about-story-image/" }) {
        childImageSharp {
          gatsbyImageData(width: 1350, height: 2093, quality: 100, layout: FIXED)
        }
      }
    }
  `);
  return (
    <div className={styles.exteriorWrapper}>
      <div className={styles.storyImageWrapper}>
        <GatsbyImage image={getImage(storyGraphic)} className={styles.storyImage} alt="" />
      </div>
      <section className={`container ${styles.wrapper}`}>
        <div className={`row ${styles.innerWrapper}`}>
          <div className={`col-lg-5 col-12 ${classNames(styles.cardWrapper, styles.first)}`}>
            <Heading tag={'h3'} size={'md'}>
              It all started with a game...
            </Heading>
            <p className={styles.description}>
              In the year 2000, with the Y2K crisis in the rear view mirror, the two founders were working on a MMORPG.
              With a goal to support hundreds of players simultaneously the need for some early load testing arose. They
              opted to open a consultancy to aid organizations in load testing. The most notable customer from this era
              was the European Space Agency where we got to test IP satellite networks.
            </p>
          </div>
        </div>
        <div className={`row ${styles.innerWrapper}`}>
          <div className={`col-lg-5 col-12 ${classNames(styles.cardWrapper, styles.second)}`}>
            <Heading tag={'h3'} size={'md'}>
              ...then we added some Alligators...
            </Heading>
            <p className={styles.description}>
              Meanwhile - one of the founders started to take on a new hobby. An interest in reptiles, specifically
              Spectacled Caiman alligators. Two of them soon moved into our Stockholm office, Bert and Ed. Bert proved
              to be mischievous, and escaped on a few occasions. They eventually moved out to go live in more
              appropriate accommodations - however Bert’s legacy lives on serving as our mascot.
            </p>
          </div>
        </div>
        <div className={`row ${styles.innerWrapper}`}>
          <div className={`col-lg-5  col-12 ${classNames(styles.cardWrapper, styles.third)}`}>
            <Heading tag={'h3'} size={'md'}>
              …we then shifted from consultancy to a SaaS organization….
            </Heading>
            <p className={styles.description}>
              Fast-forwarding to the year 2008 after years of consulting, we shifted gears yet again. The concept of
              “cloud computing” was emerging and the possibility to bring the load testing tool we created to market
              became a viable business model. Initially the focus was on website testing - DevOps was still a fringe
              concept only recently being coined.
            </p>
          </div>
        </div>
        <div className={`row ${styles.innerWrapper}`}>
          <div className={`col-lg-5 col-12 ${classNames(styles.cardWrapper, styles.fourth)}`}>
            <Heading tag={'h3'} size={'md'}>
              … we knew gaps still existed in the market …
            </Heading>
            <p className={styles.description}>
              In tandem with the growth of DevOps, we started to notice gaps emerging in the market that we knew we
              could address. Users were no longer testing things one-off. APIs were becoming more prevalent in usage and
              required testing. Users wanted to test continuously. For many companies, testing was moving into the
              responsibility of developers.
            </p>
          </div>
        </div>
        <div className={`row ${styles.innerWrapper}`}>
          <div className={`col-lg-5 col-12 ${classNames(styles.cardWrapper, styles.fifth)}`}>
            <Heading tag={'h3'} size={'md'}>
              … we decided to build a new tool from the ground up …
            </Heading>
            <p className={styles.description}>
              In 2016, Bert and the LoadImpact crew started working on a new{' '}
              <Link to={'/open-source/'} className={'link'}>
                open-source load testing tool
              </Link>
              . A tool that developers could love in the 21st century.
            </p>
            <p className={styles.description}>
              Moving on to the present day, early adoption has been fantastic and the k6 community quickly grew. We have
              something special in our hands and look forward to building it with you.
            </p>
          </div>
        </div>
        <div className={`row ${styles.innerWrapper}`}>
          <div className={`col-lg-5 col-12 ${classNames(styles.cardWrapper, styles.sixth)}`}>
            <Heading tag={'h3'} size={'md'}>
              … extending core k6 …
            </Heading>
            <p className={styles.description}>
              Early 2020 we launched the first tool to extend core k6. <br className={'d-none d-sm-none d-xl-block'} />
              The <CodeInline>xk6</CodeInline> extensions ecosystem is ever expanding, allowing for better integrations
              in users&apos; existing stack.
            </p>
          </div>
        </div>
        <div className={`row ${styles.innerWrapper}`}>
          <div className={`col-lg-5 col-12 ${classNames(styles.cardWrapper, styles.seventh)}`}>
            <Heading tag={'h3'} size={'md'}>
              … we joined Grafana Labs …
            </Heading>
            <p className={styles.description}>
              June 2021 we were <a href="https://k6.io/blog/joining-grafana-labs/">acquired by Grafana Labs</a>.
              Announced by Raj Dutt during his closing keynote at GrafanaCONline, we welcomed the opportunity to
              accelerate on our mission: to give modern engineering teams better tools to build reliable applications.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};
