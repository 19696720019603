import classNames from 'classnames';
import { Button } from 'components/shared/button';
import { Heading } from 'components/shared/heading';
import { Lines } from 'components/shared/lines';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useRef, useState } from 'react';
import ReactIdSwiperCustom from 'react-id-swiper/lib/ReactIdSwiper.custom';
import Corner from 'svg/corner.inline.svg';
import TwitterIcon from 'svg/twitter.inline.svg';
import { Swiper, Navigation, Pagination } from 'swiper/swiper.esm';

// keep the order intact
import 'swiper/swiper-bundle.css';
import styles from './home-feedbacks.module.scss';
import './home-feedbacks.scss';
// icons
import ArrowIcon from './svg/arrow.inline.svg';
import RectangleSm from './svg/cta-rectangle-sm.inline.svg';
import Rectangle from './svg/cta-rectangle.inline.svg';

export const Feedbacks = () => {
  const [isPrevDisabled, setIsPrevDisabled] = useState(true);
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const swiperRef = useRef(null);
  const {
    allTweetsJson: { edges: TWEETS },
  } = useStaticQuery(graphql`
    query tweetsQuery {
      allTweetsJson {
        edges {
          node {
            text
            author {
              name
              username
              avatar
            }
            tweetURL
          }
        }
      }
    }
  `);

  const goNext = () => {
    if (swiperRef?.current?.swiper) {
      swiperRef.current.swiper.slideNext();
      setIsPrevDisabled(false);
    }
  };
  const goPrev = () => {
    if (swiperRef?.current?.swiper) {
      swiperRef.current.swiper.slidePrev();
      setIsNextDisabled(false);
    }
  };

  return (
    <section className={styles.wrapper}>
      <div className={'container'}>
        <Heading className={styles.title} tag={'h3'}>
          What our users are saying
        </Heading>

        <div className={styles.swiperWrapper}>
          <ReactIdSwiperCustom
            Swiper={Swiper}
            containerClass={`swiper-container ${styles.itemsWrapper}`}
            modules={[Navigation, Pagination]}
            ref={swiperRef}
            slidesPerView={1}
            slidesPerGroup={1}
            spaceBetween={25}
            loopFillGroupWithBlank
            breakpoints={{
              575.98: {
                slidesPerView: 2,
                slidesPerGroup: 2,
                spaceBetween: 25,
              },
              991.98: {
                slidesPerView: 3,
                slidesPerGroup: 3,
                spaceBetween: 25,
              },
            }}
            pagination={{
              el: '.swiper-pagination',
              type: 'bullets',
              clickable: true,
            }}
            on={{
              reachBeginning: () => setIsPrevDisabled(true),
              reachEnd: () => setIsNextDisabled(true),
            }}
          >
            {TWEETS.map(
              ({
                node: {
                  text,
                  author: { name, username, avatar },
                  tweetURL,
                },
              }) => (
                <div className={styles.itemWrapper} key={tweetURL}>
                  <a className={styles.item} href={tweetURL} target={'_blank'} rel={'noreferrer'}>
                    <div className={styles.itemInner}>
                      <div className={styles.itemIconWrapper}>
                        <TwitterIcon className={styles.itemIcon} />
                      </div>
                      {/* eslint-disable-next-line react/no-danger */}
                      <p className={styles.itemText} dangerouslySetInnerHTML={{ __html: text }} />
                      <div className={styles.itemFooter}>
                        <img className={styles.itemAuthorAvatar} src={avatar} alt={name} />
                        <div className={styles.itemAuthorDetails}>
                          <h3 className={styles.itemAuthorName}>{name}</h3>
                          <span className={styles.itemAuthorUsername}>{username}</span>
                        </div>
                      </div>
                      <Corner className={styles.itemCorner} />
                    </div>
                  </a>
                </div>
              )
            ).concat(
              <div className={styles.itemWrapper} key={'cta'}>
                <div className={styles.item}>
                  <div className={classNames(styles.itemInner, styles.itemInnerCta)}>
                    <p className={styles.ctaText}>Why people love k6?</p>
                    <Button
                      className={styles.ctaBtn}
                      size={'sm'}
                      tag={'link'}
                      type={'button'}
                      to={'/testimonials/'}
                      cursor
                    >
                      read more
                    </Button>
                    <RectangleSm className={styles.rectangleSm} />
                    <Rectangle className={styles.rectangle} />
                    <Corner className={styles.itemCorner} />
                  </div>
                </div>
              </div>
            )}
          </ReactIdSwiperCustom>
          <Button
            tag={'button'}
            type={'button'}
            shape={'round'}
            theme={'gradient-tertiary'}
            onClick={goNext}
            className={`${styles.sliderControl} ${isNextDisabled && styles.sliderControlHidden}`}
          >
            <ArrowIcon />
          </Button>
          <Button
            tag={'button'}
            type={'button'}
            shape={'round'}
            theme={'gradient-tertiary'}
            onClick={goPrev}
            // eslint-disable-next-line max-len
            className={`${styles.sliderControl} ${styles.sliderControlPrev} ${
              isPrevDisabled && styles.sliderControlHidden
            }`}
          >
            <ArrowIcon />
          </Button>
        </div>
      </div>

      <Lines imageNumber={7} className={styles.linesWrapper} />
    </section>
  );
};
